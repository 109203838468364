import dayjs from 'dayjs'
import { CommandeHistory } from '../../../models/History'
import { CommandeEventType, GroupType } from '../enum/enum'
import { LineData } from '../table/model'
import { Unit } from '../../../enum/Unit'
import {
  getElementFromObjectSearchType,
  getElementFromObjectType,
} from '../func'
import { randomString } from '../../../utils'

const splitString = '_-_'

function getValues(values: string) {
  if (values.includes(splitString)) {
    // const newValues = values.substring(0, values.length - 3);
    const valueList = values.split(splitString)
    return valueList
  }

  return []
}

export function buildLines(commandeHistories: CommandeHistory[]): LineData[] {
  const result: LineData[] = []

  commandeHistories.forEach((commandeHistory, idx) => {
    const eventValues = mapTypeToEvent(commandeHistory)
    const {
      created_at = 0,
      user_name = '',
      chrono = '',
      event_type = CommandeEventType.TYPE_NULL,
      object_type = '',
      object_number = '',
      object_search_type,
    } = commandeHistory
    const group = getGroup(event_type)
    const mainLine: LineData = {
      id: randomString(16),
      data: [
        { name: 'chrono', value: chrono },
        { name: 'date', value: String(created_at * 1000) },
        { name: 'heure', value: String(created_at * 1000) },
        { name: 'utilisateur', value: user_name },
        { name: 'object_type', value: getElementFromObjectType(object_type) },
        { name: 'object_number_or_sscc', value: object_number },
        {
          name: 'object_search_type',
          value: getElementFromObjectSearchType(object_search_type || ''),
        },
      ],
    }
    result.push(mainLine)

    if (eventValues.length === 1) {
      let evenementValue = `${group}: ${eventValues[0].event}`
      if (evenementValue.includes('Edition: Edition')) {
        const evenementValueSplit = evenementValue.split('Edition: Edition')
        evenementValue = 'Edition: ' + evenementValueSplit[1]
      }

      if (evenementValue.includes('Création: Création')) {
        const evenementValueSplit = evenementValue.split('Création: Création')
        evenementValue = 'Création: ' + evenementValueSplit[1]
      }
      if (evenementValue.includes('Suppression: Suppression')) {
        const evenementValueSplit = evenementValue.split(
          'Suppression: Suppression'
        )
        evenementValue = 'Suppression: ' + evenementValueSplit[1]
      }
      mainLine.data.push({ name: 'evenement', value: evenementValue })
      mainLine.data.push({ name: 'value', value: `${eventValues[0].value}` })
    } else {
      mainLine.data.push({ name: 'evenement', value: `${group}` })
      mainLine.childrenLineId = []

      eventValues.forEach((eventValue, idx) => {
        const childId = randomString(16)
        if (mainLine.childrenLineId) mainLine.childrenLineId.push(childId)

        const childLine: LineData = {
          id: childId,
          parentLineId: mainLine.id,
          data: [
            { name: 'chrono', value: chrono },
            { name: 'date', value: String(created_at * 1000) },
            { name: 'heure', value: String(created_at * 1000) },
            { name: 'utilisateur', value: user_name },
            { name: 'evenement', value: eventValue.event },
            { name: 'value', value: eventValue.value },
          ],
        }
        result.push(childLine)
      })
    }
  })

  return result
}

export function mapTypeToEvent(
  commandeHistory: CommandeHistory
): { event: string; value: string }[] {
  const {
    event_type = CommandeEventType.TYPE_NULL,
    changes = [],
    object_number = '',
    description,
  } = commandeHistory
  const group = getGroup(event_type)

  switch (event_type) {
    case CommandeEventType.C1:
      return [{ event: `Création Commande`, value: object_number }]
    case CommandeEventType.C2:
      return [{ event: `Suppression Commande`, value: object_number }]

    case CommandeEventType.C8:
      return [{ event: `Mise en préparation Commande`, value: object_number }]
    case CommandeEventType.C910111213: {
      let final: { event: string; value: string }[] = []
      changes.forEach((change) => {
        const changeEventType = change.event_type
        const { field = '', value } = change
        let { old_value, new_value } = change

        const label = mapFieldToLabel(field)
        if (['LivraisonAt', 'ChargementAt'].includes(field)) {
          // if(old_value === '0' && new_value === '0') old_value = new_value = '';
          if (old_value !== '0' && old_value) {
            old_value = dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')
            new_value = ''
          }
          if (new_value !== '0' && new_value) {
            new_value = dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')
            old_value = ''
          }
        }

        if (field === 'Transporteur.CodeNom') {
          if (!old_value) old_value = 'Enlèvement client'
          if (!new_value) new_value = 'Enlèvement client'
        }

        if (changeEventType === CommandeEventType.C9) {
          handle_C3C9(value, field, label, final, old_value, new_value)
        }

        if (changeEventType === CommandeEventType.C10) {
          handle_C4C10(
            field,
            value || '',
            old_value || '',
            new_value || '',
            label,
            final
          )
        }
        if (changeEventType === CommandeEventType.C11) {
          handleDelete(value, field, final)
        }

        if (changeEventType === CommandeEventType.C12) {
          if (field.includes('ComFile.[')) {
            final.push({ event: `Ajout document`, value: value || '' })
          } else if (field === 'ComArticle') {
            handleAddNewRef(value, field, final)
          }
        }
        if (changeEventType === CommandeEventType.C13) {
          handle_C7C13(new_value, label, final)
        }
      })
      return final
    }

    case CommandeEventType.C34567:
      let final: { event: string; value: string }[] = []

      changes.forEach((change) => {
        let changeEventType = change.event_type
        const { field = '', value } = change
        let { old_value, new_value } = change

        const label = mapFieldToLabel(field)
        if (
          ['LivraisonAt', 'ChargementAt'].includes(field) ||
          field.includes('].Dluo')
        ) {
          const { old_value: old_value_result, new_value: new_value_result } =
            handleTime(old_value, new_value)
          old_value = old_value_result
          new_value = new_value_result
        }

        if (field === 'Transporteur.CodeNom') {
          if (!old_value) old_value = 'Enlèvement client'
          if (!new_value) new_value = 'Enlèvement client'
          changeEventType = CommandeEventType.C3
        }

        if (changeEventType === CommandeEventType.C3) {
          handle_C3C9(value, field, label, final, old_value, new_value)
        }
        if (changeEventType === CommandeEventType.C4) {
          handle_C4C10(
            field,
            value || '',
            old_value || '',
            new_value || '',
            label,
            final
          )
        }
        if (changeEventType === CommandeEventType.C5) {
          handleDelete(value, field, final)
        }
        if (changeEventType === CommandeEventType.C6) {
          if (field === 'ComArticle') {
            handleAddNewRef(value, field, final)
          } else if (field === 'ComFile') {
            final.push({
              event: `Ajout document`,
              value: (value || '_-_')?.split('_-_')[0],
            })
          } else if (
            ['Commande.Destinataire', 'Commande.Transporteur'].includes(field)
          ) {
            final.push({ event: `Renseignement ${label}`, value: value || '' })
          }
        }

        if (changeEventType === CommandeEventType.C7) {
          handle_C7C13(new_value, label, final)
        }
      })
      return final

    case CommandeEventType.C8:
      return [{ event: `Envoi en mission Attendu`, value: object_number }]
    case CommandeEventType.C9:
      return [
        { event: `Entrée sur Attendu (${object_number}) à quai`, value: '' },
      ]
    case CommandeEventType.C10:
      return [
        { event: `Entrée sur Attendu (${object_number}) au stock`, value: '' },
      ]

    case CommandeEventType.C11:
      return [
        { event: `Entrée sur Attendu (${object_number}) au stock`, value: '' },
      ]
    case CommandeEventType.C17:
      return [{ event: `Chargé pour commande`, value: object_number }]

    case CommandeEventType.C141:
      return [{ event: `Envoi en mission pour Commande`, value: object_number }]

    case CommandeEventType.C142:
      return [
        { event: `Envoi en chargement pour Commande`, value: object_number },
      ]

    case CommandeEventType.C15:
      const [previewed, exchanged, mission] = (
        description || '_-__-__-_'
      ).split('_-_')
      return [
        {
          event: `Edition: Échange palette`,
          value: `(${previewed}) pour (${exchanged}) durant mission (${mission})`,
        },
      ]

    case CommandeEventType.C16:
      return [{ event: `Préparé pour commande`, value: object_number }]

    case CommandeEventType.C17:
      return [{ event: `Chargé pour commande`, value: object_number }]
  }
  return [{ event: '', value: '' }]
}

export function getGroup(eventType: CommandeEventType): GroupType {
  switch (eventType) {
    case CommandeEventType.C1:
      return GroupType.Creation
    case CommandeEventType.C2:
      return GroupType.Suppression

    case CommandeEventType.C3:
    case CommandeEventType.C4:
    case CommandeEventType.C5:
    case CommandeEventType.C6:
    case CommandeEventType.C7:
    case CommandeEventType.C8:
    case CommandeEventType.C9:
    case CommandeEventType.C10:
    case CommandeEventType.C11:
    case CommandeEventType.C12:
    case CommandeEventType.C13:
    case CommandeEventType.C14:
    case CommandeEventType.C15:
    case CommandeEventType.C34567:
    case CommandeEventType.C141:
    case CommandeEventType.C142:
    case CommandeEventType.C910111213:
      return GroupType.Edition

    case CommandeEventType.C16:
    case CommandeEventType.C17:
      return GroupType.Sortie
    case CommandeEventType.C18:
      return GroupType.Special_case
  }

  return GroupType.NULL
}

function mapFieldToLabel(field: string): string {
  if (field.includes('[') && field.includes(']')) {
    if (field.includes('ComArticle') && field.includes('TotalPieses'))
      return 'Total pièces'
    if (field.includes('ComArticle') && field.includes('Quantite')) return 'Qte'
    if (field.includes('.Conditionnement.Qte')) return 'Qte'
    if (field.includes('ComArticle') && field.includes('Lot')) return 'Lot'

    if (field.includes('ComArticle') && field.includes('Sscc')) return 'SSCC'
    if (field.includes('ComArticle') && field.includes('Conditionnement.Nom'))
      return 'Cdn'
    if (field.includes('ComArticle') && field.includes('Dluo')) return 'DLUO'
    if (field.includes('ComArticle') && field.includes('Libelle'))
      return 'Libellé'
    if (field.includes('AttProduction') && field.includes('SousQte'))
      return 'Sous-Qte'

    if (field.includes('AttProduction') && field.includes('DLUO')) return 'DLUO'
    if (field.includes('AttProduction') && field.includes('ReferenceNumber'))
      return 'Référence'
    if (
      field.includes('AttProduction') &&
      field.includes('RefFournisseurNumber')
    )
      return 'Réf fournisseur'
    if (field.includes('AttProduction') && field.includes('Libelle'))
      return 'Libellé'
    if (field.includes('AttProduction') && field.includes('Lot')) return 'Lot'
    if (field.includes('AttProduction') && field.includes('Conditionement'))
      return 'Cdn'
    if (field.includes('AttProduction') && field.includes('Sscc')) return 'SSCC'
  }

  switch (field) {
    case 'NomClient':
      return 'N° client'
    case 'AttCommentaire.Content':
      return 'Commentaire'

    case 'NumberOfFournisseur':
      return 'N° Fournisseur'

    case 'NoCommande':
      return 'N° Commande'

    case 'Priorite':
      return 'Priorité'

    case 'Livraison':
      return 'Livraison'

    case 'Commande':
      return 'Commande'

    case 'AttFournisseur.CodeNom':
      return 'Code & Nom Fournisseur'

    case 'Photos':
      return 'Photo(s)'

    case 'ForceDlou':
      return 'Forcer la DLUO'

    case 'Imperatif':
      return 'Impératif'

    case 'ActiveLot':
      return 'Active'

    case 'ChargementAt':
      return 'Chargement'

    case 'LivraisonAt':
      return 'Livraison'

    case 'ComPreparation.Comment':
      return 'Commentaire Préparation'

    case 'ComLivraison.Comment':
      return 'Commentaire Livraison'

    case 'Destinataire.CodeNom':
      return 'Code & Nom Destinataire'

    case 'Transporteur.CodeNom':
      return 'Code & Nom Transporteur'
  }

  return ''
}

function displayValue(name: string, value: any) {
  if (!value) return

  switch (name) {
    case 'Lot':
    case 'Cdn':
    case 'Qte':
      return `${name}(${value})`
    case 'SSCC':
      return `${name}(${value})`
    case 'DLUO':
      if (value === '0') return ''
      return `${name}(${dayjs(Number(value) * 1000).format('DD/MM/YYYY')})`
  }

  return ''
}

function getSymbol(value: string | undefined, unit?: Unit) {
  if (value) return `${value}${unit || ''}`
  return 'Non renseigné'
}

function handle_C4C10(
  field: string,
  value: string | undefined,
  old_value: string | undefined,
  new_value: string | undefined,
  label: string,
  final: { event: string; value: string }[]
) {
  if (field.includes('ComArticle[')) {
    const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
    const dLot = displayValue('Lot', lot)
    const dSscc = displayValue('SSCC', sscc)
    const dDluo = displayValue('DLUO', dluo)
    const dCdn = displayValue('Cdn', cdn)
    const dQte = displayValue('Qte', qte)

    if (old_value) {
      const listText = [
        `Edition ${label} Réf(${refName})`,
        dLot,
        dSscc,
        dDluo,
        dCdn,
        dQte,
      ].filter((item) => item)
      final.push({
        event: listText.join(', '),
        value: `${old_value} \u279E ${getSymbol(new_value)}`,
      })
    } else if (new_value) {
      const listText = [
        `Renseignement ${label} Réf(${refName})`,
        dLot,
        dSscc,
        dDluo,
        dCdn,
        dQte,
      ].filter((item) => item)
      final.push({
        event: listText.join(', '),
        value: getSymbol(new_value),
      })
    }
  } else if (new_value)
    final.push({ event: `Renseignement ${label}`, value: new_value })
  else
    final.push({
      event: `Edition ${label}`,
      value: `${old_value} \u279E Non renseigné`,
    })
}

function handleTime(
  old_value: string | undefined,
  new_value: string | undefined
) {
  if (old_value !== '0' && new_value !== '0') {
    old_value = dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')
    new_value = dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')
  } else {
    if (old_value !== '0' && old_value) {
      old_value = dayjs(Number(old_value) * 1000).format('DD/MM/YYYY')
      new_value = ''
    }
    if (new_value !== '0' && new_value) {
      new_value = dayjs(Number(new_value) * 1000).format('DD/MM/YYYY')
      old_value = ''
    }
  }
  return { old_value, new_value }
}

function handle_C7C13(
  new_value: string | undefined,
  label: string,
  final: { event: string; value: string }[]
) {
  if (new_value == 'true') {
    final.push({ event: `${label}`, value: 'Activé' })
  } else final.push({ event: `${label}`, value: 'Désactivé' })
}

function handleAddNewRef(
  value: string | undefined,
  field: string,
  final: { event: string; value: string }[]
) {
  const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
  const dLot = displayValue('Lot', lot)
  const dSscc = displayValue('SSCC', sscc)
  const dDluo = displayValue('DLUO', dluo)
  const dCdn = displayValue('Cdn', cdn)
  const dQte = displayValue('Qte', qte)
  if (!field.includes('].')) {
    const listText = [`Réf(${refName})`, dLot, dSscc, dDluo, dCdn, dQte].filter(
      (item) => item
    )
    final.push({ event: 'Renseignement Réf', value: listText.join(',') })
  }
}

function handle_C3C9(
  value: string | undefined,
  field: string,
  label: string,
  final: { event: string; value: string }[],
  old_value: string | undefined,
  new_value: string | undefined
) {
  if (field.includes('ComArticle[')) {
    const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
    const dLot = displayValue('Lot', lot)
    const dSscc = displayValue('SSCC', sscc)
    const dDluo = displayValue('DLUO', dluo)
    const dCdn = displayValue('Cdn', cdn)
    const dQte = displayValue('Qte', qte)
    const listText = [
      `Edition ${label} Réf(${refName})`,
      dLot,
      dSscc,
      dDluo,
      dCdn,
      dQte,
    ].filter((item) => item)
    final.push({
      event: listText.join(', '),
      value: `${old_value} \u279E ${new_value}`,
    })
  } else
    final.push({
      event: `Edition ${label}`,
      value: `${old_value} \u279E ${new_value}`,
    })
}

function handleDelete(
  value: string | undefined,
  field: string,
  final: { event: string; value: string }[]
) {
  if (field === 'ComArticle') {
    const [refName, lot, sscc, dluo, cdn, qte] = getValues(value || '')
    const dLot = displayValue('Lot', lot)
    const dSscc = displayValue('SSCC', sscc)
    const dDluo = displayValue('DLUO', dluo)
    const dCdn = displayValue('Cdn', cdn)
    const dQte = displayValue('Qte', qte)
    if (!field.includes('].')) {
      const listText = [
        `Suppression Réf(${refName})`,
        dLot,
        dSscc,
        dDluo,
        dCdn,
        dQte,
      ].filter((item) => item)
      final.push({ event: listText.join(', '), value: '' })
    }
  } else if (field.includes('ComFile')) {
    final.push({
      event: `Suppression document`,
      value: (value || '_-_')?.split('_-_')[0],
    })
  }
}
